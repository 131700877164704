import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ManualsService } from '../../service/ManualsService';
import { ProductService } from '../../service/ProductService';
import { dateBodyTemplate } from '../../utilities/Utils';

export const Manuals = () => {
    const [expandedRows, setExpandedRows] = useState([]);
    const [products, setProducts] = useState([]);
    
    const manualsService = new ManualsService();
    const productService = new ProductService();

    useEffect(() => {
        productService.getProducts().then(data => setProducts(data));
    }, [setProducts]);

    const actionBodyTemplate = rowData => {
        return (
            <div className="actions">
                <Button icon="pi pi-download" className="p-button-rounded p-button-success p-mr-2" />
            </div>
        );
    };

    const manualsExpansionTemplate = (rowDdata) => {
        return (
            <div className="orders-subtable">
                <h5>Manuais</h5>
                <DataTable value={rowDdata.manuals}>
                    <Column field="name" header="Nome" sortable></Column>
                    <Column field="description" header="Descriçāo" sortable></Column>
                    <Column field="version" header="Versāo" sortable></Column>
                    <Column field="created_at" header="Date" sortable body={dateBodyTemplate}></Column>
                    <Column body={actionBodyTemplate} />
                </DataTable>
            </div>
        );
    }

    return (
        <div>
            <DataTable
                value={products}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                rowExpansionTemplate={manualsExpansionTemplate}
                expandedRows={expandedRows} 
                onRowToggle={(e) => setExpandedRows(e.data)}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} equipamentos"
                emptyMessage="Nenhum equipamento encontrado."
            >
                <Column expander style={{ width: '3em' }} />
                <Column field="name" />
            </DataTable>
        </div>
    );
};
