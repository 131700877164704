import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import AppTopbar from './AppTopbar';
import AppFooter from './AppFooter';
import AppRightPanel from './AppRightPanel';
import { Dashboard } from './components/Dashboard';
import { Assistance } from './pages/Assistance';
import { BankSlips } from './pages/finance/BankSlips';
import { Invoice } from './pages/finance/Invoice';
import { Manuals } from './pages/products/Manuals';
import { Maintenance } from './pages/products/Maintenance';
import { MaintenanceDetails } from './pages/products/MaintenanceDetails';

const Panel = (props: any) => {
    const [resetActiveIndex, setResetActiveIndex] = useState(false);
    const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
    const [sidebarStatic, setSidebarStatic] = useState(false);
    const [sidebarActive, setSidebarActive] = useState(false);
    const [menuActive, setMenuActive] = useState(false);
    const [menuMode, setMenuMode] = useState('sidebar');
    const [configActive, setConfigActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('filled');
    const [ripple, setRipple] = useState(false);
    const [rightPanelActive, setRightPanelActive] = useState(false);
    const [topbarScheme, setTopbarScheme] = useState('light');
    const [menuScheme, setMenuScheme] = useState('light');
    const [searchActive, setSearchActive] = useState(false);
    const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
    const menu = [
        {
            label: 'Dashboard',
            icon: 'pi pi-home',
            to: '/'
        },
        {
            label: 'Financeiro',
            icon: 'pi pi-wallet',
            items: [
                {
                    label: 'Notas Fiscais',
                    icon: 'pi pi-book',
                    to: '/finance/invoice'
                },
                {
                    label: 'Boletos',
                    icon: 'pi pi-money-bill',
                    to: '/finance/bankslips'
                }
            ]
        },
        {
            label: 'Equipamentos',
            icon: 'pi pi-tablet',
            items: [
                {
                    label: 'Manuais',
                    icon: 'pi pi-file',
                    to: '/equipament/manuals'
                },
                {
                    label: 'Manutençāo',
                    icon: 'pi pi-calendar',
                    to: '/equipament/maintenance'
                }
            ]
        },
        {
            label: 'Assistência Técnica',
            icon: 'pi pi-flag',
            to: '/assistance'
        }
    ];
    let menuClick: any;
    let rightPanelClick: any;
    let configClick: any;
    let searchClick: any;
    let topbarUserMenuClick: any;
    useEffect(
        () => {
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        },
        [staticMenuMobileActive]
    );
    useEffect(
        () => {
            setResetActiveIndex(true);
            setMenuActive(false);
        },
        [menuMode]
    );
    const onMenuItemClick = (event: any) => {
        if (!event.item.items) {
            setResetActiveIndex(true);
            hideOverlayMenu();
        }
        if (!event.item.items && (isSlim() || isHorizontal())) {
            setMenuActive(false);
        }
    };
    const onMenuClick = (event: any) => {
        if (menuActive && event.target.className === 'layout-menu-container') {
            setResetActiveIndex(true);
            setMenuActive(false);
        }
        menuClick = true;
    };
    
    const blockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    };
    const unblockBodyScroll = () => {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    };
    const onMenuButtonClick = (event: any) => {
        menuClick = true;
        setTopbarUserMenuActive(false);
        setRightPanelActive(false);
        if (isMobile()) {
            setStaticMenuMobileActive(prevStaticMenuMobileActive => !prevStaticMenuMobileActive);
            if (staticMenuMobileActive) {
                blockBodyScroll();
            } else {
                unblockBodyScroll();
            }
        }
        event.preventDefault();
    };
    const isMobile = () => {
        return window.innerWidth <= 991;
    };
    const isHorizontal = () => {
        return menuMode === 'horizontal';
    };
    const isSlim = () => {
        return menuMode === 'slim';
    };
    const hideOverlayMenu = () => {
        setStaticMenuMobileActive(false);
        unblockBodyScroll();
    };
    const onRightPanelClick = () => {
        rightPanelClick = true;
    };
    const onRightPanelButtonClick = () => {
        setRightPanelActive(prevState => !prevState);
        rightPanelClick = true;
    };
    const onTopbarSearchToggle = () => {
        setSearchActive(prevState => !prevState);
        searchClick = true;
    };
    const onTopbarSearchClick = () => {
        searchClick = true;
    };
    const onTopbarUserMenuClick = () => {
        setTopbarUserMenuActive(prevState => !prevState);
        topbarUserMenuClick = true;
    };
    const onDocumentClick = () => {
        if (!searchClick && searchActive) {
            setSearchActive(false);
            searchClick = false;
        }
        if (!topbarUserMenuClick && topbarUserMenuActive) {
            setTopbarUserMenuActive(false);
            topbarUserMenuClick = false;
        }
        if (!rightPanelClick && rightPanelActive) {
            setRightPanelActive(false);
        }
        if (!configClick && configActive) {
            setConfigActive(false);
        }
        if (!menuClick) {
            if (isSlim() || isHorizontal()) {
                setResetActiveIndex(true);
                setMenuActive(false);
            }
            if (staticMenuMobileActive) {
                hideOverlayMenu();
            }
            unblockBodyScroll();
        }
        searchClick = false;
        topbarUserMenuClick = false;
        rightPanelClick = false;
        configClick = false;
        menuClick = false;
    };
    const onSidebarMouseOver = () => {
        setSidebarActive(!isMobile());
    };
    const onSidebarMouseLeave = () => {
        setSidebarActive(false);
    };
    const onToggleMenu = (event: any) => {
        menuClick = true;
        setSidebarStatic(prevState => !prevState);
        event.preventDefault();
    };
    const onRootMenuItemClick = () => {
        setMenuActive(prevMenuActive => !prevMenuActive);
    };
    const layoutClassName = classNames(
        'layout-wrapper',
        {
            'layout-sidebar': menuMode === 'sidebar',
            'layout-static': menuMode === 'sidebar' && sidebarStatic,
            'layout-horizontal': menuMode === 'horizontal',
            'layout-rightpanel-active': rightPanelActive,
            'layout-slim': menuMode === 'slim',
            'layout-mobile-active': staticMenuMobileActive,
            'p-input-filled': inputStyle === 'filled',
            'p-ripple-disabled': !ripple
        },
        'layout-menu-' + menuScheme + ' layout-topbar-' + topbarScheme
    );
    return (
        <div className={layoutClassName} onClick={onDocumentClick}>
            <AppTopbar
                topbarScheme={topbarScheme}
                onRightPanelButtonClick={onRightPanelButtonClick}
                searchActive={searchActive}
                onTopbarSearchToggle={onTopbarSearchToggle}
                onTopbarSearchClick={onTopbarSearchClick}
                topbarUserMenuActive={topbarUserMenuActive}
                onTopbarUserMenuClick={onTopbarUserMenuClick}
                menu={menu}
                menuActive={menuActive}
                onRootMenuItemClick={onRootMenuItemClick}
                mobileMenuActive={staticMenuMobileActive}
                onMenuItemClick={onMenuItemClick}
                menuMode={menuMode}
                sidebarStatic={sidebarStatic}
                sidebarActive={sidebarActive}
                onSidebarMouseOver={onSidebarMouseOver}
                onSidebarMouseLeave={onSidebarMouseLeave}
                onToggleMenu={onToggleMenu}
                onMenuButtonClick={onMenuButtonClick}
                resetActiveIndex={resetActiveIndex}
                onMenuClick={onMenuClick}
            />

            <AppRightPanel onRightPanelClick={onRightPanelClick} />

            <div className="layout-main">
                <div className="layout-content">
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/assistance" component={Assistance} />
                    <Route path="/equipament/maintenance" exact component={Maintenance} />
                    <Route path="/equipament/maintenance/details" exact component={MaintenanceDetails} />
                    <Route path="/equipament/manuals" component={Manuals} />
                    <Route path="/finance/invoice" component={Invoice} />
                    <Route path="/finance/bankslips" component={BankSlips} />
                    <Route path="/products/manuals" component={BankSlips} />
                </div>

                <AppFooter />
            </div>

            <div className="layout-mask modal-in" />
        </div>
    );
};
export default Panel;
