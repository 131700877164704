import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { formatCurrencyTemplate } from "../../utilities/Utils";
import { BankSlipService } from "../../service/BankSlipService";
export const BankSlips = () => {
    
    const [bank_slips, setBankSlips] = useState([]);
    
    const bankSlipService = new BankSlipService();

    useEffect(() => {
        bankSlipService.getBankSlips().then(data => setBankSlips(data));
    }, [setBankSlips]);

    const actionBodyTemplate = rowData => {
        return (
            <div className="actions">
                <Button icon="pi pi-download" className="p-button-rounded p-button-success p-mr-2" />
            </div>
        );
    };

    return (
        <div>
            <DataTable
                value={bank_slips}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} boletos"
                emptyMessage="Nenhuma nota fiscal encontrada."
            >
                <Column field="number" header="Número" sortable />
                <Column field="due_date" header="Data de Vencimento" sortable />
                <Column field="value" header="Valor" sortable body={formatCurrencyTemplate} />
                <Column body={actionBodyTemplate} />
            </DataTable>
        </div>
    );
};
