import React from 'react';
import Router from './Router';

import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.scss';

const App = () => {
    return (
        <Router />
    );
}

export default App;