import React, { useCallback, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { useHistory } from "react-router-dom";
import { useAuth } from "../contexts/Auth";
import { getFormErrorMessage } from "../utilities/Utils";
import classNames from "classnames";
import { Controller, useForm } from 'react-hook-form';

export const Login = () => {
	const auth = useAuth();
    const history = useHistory();

    const [formData, setFormData] = useState({});
    const [showMessage, setShowMessage] = useState(false);

    const { control, formState: { errors }, handleSubmit } = useForm();

    const onSubmit = useCallback(async (data: any) => {
        setFormData(data);

        const authResult = await auth.signIn(data);

        if(authResult){
            history.push("/");
        }else{
            setShowMessage(true);
        }
    }, []);
	
	return (
		<div className="login-body">
			<div className="login-wrapper">
				<form onSubmit={handleSubmit(onSubmit)} className="login-panel">
					<img src="/assets/layout/images/logo-sigamaq.png" alt="logo" />

					<Controller name="username" defaultValue="" control={control} rules={{ required: 'Usuário é obrigatório.' }} render={({ field, fieldState }) => (
						<InputText id={field.name} {...field} autoFocus className={classNames({ 'p-invalid': fieldState.invalid })} />
					)} />
					{getFormErrorMessage(errors, 'username')}
					<Controller name="password" defaultValue="" control={control} rules={{ required: 'Senha é obrigatório.' }} render={({ field, fieldState }) => (
						<Password id={field.name} {...field} toggleMask={true} feedback={false} className={classNames({ 'p-invalid': fieldState.invalid })} />
					)} />
					{getFormErrorMessage(errors, 'password')}
					<Button type="submit" label="ENTRAR" className="p-button-md"/>
				</form>
				<div className="login-footer">
					<h4>SIGAMAQ</h4>
					<h6>Portal do Cliente</h6>
				</div>
			</div>
		</div>
	);
};

