import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from "react-router-dom";

export const Maintenance = () => {
    const history = useHistory();

    const [scheduledMaintenances, setScheduledMaintenances] = useState([]);
    
    useEffect(() => {
        setScheduledMaintenances([
                                    {id: 1, name: 'SIGA 6000', date: '08/10/2021', executedMaintenances: [{date: '10/04/2021', name: 'Geral'}]},
                                    {id: 1, name: 'SIGA 6010', date: '08/11/2021', executedMaintenances: [{date: '10/05/2021', name: 'Geral'}]}
                                ])
    }, [setScheduledMaintenances]);

    const actionBodyTemplate = rowData => {
        return (
            <div className="actions">
                <Button icon="pi pi-info-circle" className="p-button-rounded p-button-warning p-mr-2" onClick={() => history.replace('/equipament/maintenance/details')} />
                <Button icon="pi pi-calendar" className="p-button-rounded p-button-success p-mr-2" />
            </div>
        );
    };

    return (
        <div>
            <DataTable
                value={scheduledMaintenances}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Mostrando {first} até {last} de {totalRecords} notas fiscais"
                emptyMessage="Nenhuma nota fiscal encontrada."
            >
                <Column field="name" header="Nome" sortable />
                <Column field="date" header="Data de Emissāo" sortable />
                <Column body={actionBodyTemplate} />
            </DataTable>
        </div>
    );
};
