export const formatCurrencyTemplate = (data) => {
    return data.value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
}

export const getFormErrorMessage = (errors: any, name: string) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
};

export function dateBodyTemplate(rowData, props) {
    const date = new Date(rowData[props.field]);
    return date.toLocaleDateString("pt-br");
}