import React from 'react';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

export const MaintenanceDetails = () => {
    const history = useHistory();

    const customEvents = [
        { status: 'Manutençāo Preventiva', date: '15/10/2020 14:00', icon: 'pi pi-check', color: '#607D8B' },
        { status: 'Troca de Motor', date: '15/10/2020 16:15', icon: 'pi pi-check', color: '#607D8B' },
        { status: 'Manutençāo Preventiva', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#607D8B' }
    ];

    const customizedContent = item => {
        return (
            <Card title={item.status} subTitle={item.date}>
                {item.image && <img src={`assets/demo/images/product/${item.image}`} onError={e => (e.currentTarget.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')} alt={item.name} width={200} className="p-shadow-2" />}
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore sed consequuntur error repudiandae numquam deserunt quisquam repellat libero asperiores earum nam nobis, culpa ratione quam perferendis esse, cupiditate
                    neque quas!
                </p>
            </Card>
        );
    };
    const customizedMarker = item => {
        return (
            <span className="custom-marker p-shadow-2" style={{ backgroundColor: item.color }}>
                <i className={classNames('marker-icon', item.icon)} />
            </span>
        );
    };
    return (
        <div className="p-grid timeline-demo">
            <div className="p-col-12">
                <div className="card">
                    <h4>
                        <Button icon="pi pi-chevron-left" className="p-mr-2" onClick={() => history.goBack()}/>
                        Timeline de Manutençāo - SIGA 6000
                    </h4>

                    <h5>Manutenções realizadas</h5>
                    <Timeline value={customEvents} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                </div>
            </div>
        </div>
    );
};
