import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
const AppFooter = () => {
    const history = useHistory();
    return (
        <div className="layout-footer">
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="footer-bottom">
                        <h4>SIGAMAQ</h4>
                        <h6>Painel do Cliente</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AppFooter;
